import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MessageSuggestion.module.scss";

import Button from "../button/Button";
import CheckboxInput from "../checkboxInput/CheckboxInput";
import Modal from "../modal/Modal";
import PulseLoader from "../pulseLoader/PulseLoader";

import ChevronLeft from "~/assets/chevron-left-next-step.svg";
import ChevronRight from "~/assets/chevron-right-next-step.svg";
import Copy from "~/assets/copy.svg";
import Flag from "~/assets/flag.svg";
import {
  useCoachSuggestedMessages,
  useReportMessage
} from "~/hooks/useApi/useCoachSuggestedMessages";
import {
  KeyboardShortcuts,
  useKeyboardShortcut
} from "~/hooks/useKeyboardShortcut";
import { t } from "~/i18n";
import { TaskPageContext } from "~/pages/nextStep/TaskPage.context";
import { useAmplitudeTracking } from "~/tracking/useAmplitudeTracking";
import { MessageSuggestionType } from "~/typing/sidekickTypes";

type MessageSuggestionProps = {
  title?: string;
  messageType: MessageSuggestionType;
  numMessages: number;
  programId: string;
  locale: string;
  userId: string;
};

//These are the default reasons for reporting a message.
//We send these to the backend when the user reports a message.
const DEFAULT_REPORT_REASONS_STRINGS = [
  "The sentence is poorly written",
  "The data within the sentence looks strange",
  "The sentence should not appear for this user"
];

const DEFAULT_REPORT_REASONS = new Map([
  [DEFAULT_REPORT_REASONS_STRINGS[0], false],
  [DEFAULT_REPORT_REASONS_STRINGS[1], false],
  [DEFAULT_REPORT_REASONS_STRINGS[2], false]
]);

const MessageSuggestion = ({
  title = t("messages.suggestedReply"),
  numMessages,
  messageType,
  programId,
  locale,
  userId
}: MessageSuggestionProps) => {
  useKeyboardShortcut([
    {
      keys: KeyboardShortcuts.copy,
      callback: () => handleCopy()
    },
    {
      keys: KeyboardShortcuts.report,
      callback: () => setShowReportModal(true)
    }
  ]);

  const { setCopiedMessage, setCopiedMessageId } = useContext(TaskPageContext);
  const { t } = useTranslation();

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const [showReportModal, setShowReportModal] = useState(false);

  const [reportMessage, setReportMessage] = useState("");

  const [reportReasons, setReportReasons] = useState<Map<string, boolean>>(
    new Map(DEFAULT_REPORT_REASONS)
  );

  const { messages, isLoading, isError } = useCoachSuggestedMessages({
    programId,
    locale,
    userId,
    numMessages: numMessages,
    messageType: messageType
  });

  const {
    trackMessageSuggestionCopied,
    trackMessageSuggestionChanged
  } = useAmplitudeTracking();

  const { reportMessageMutation } = useReportMessage();

  /* Hidden until features are implemented on the backend */
  // const handleRefresh = () => {
  //   queryClient.invalidateQueries({
  //     queryKey: QueryKeyFactory.users.suggestedMessages(
  //       programId,
  //       locale,
  //       userId,
  //       messageType
  //     )
  //   });
  // };

  const handleCopy = () => {
    if (!messages[currentMessageIndex]?.message) return;
    setCopiedMessage &&
      setCopiedMessage(messages[currentMessageIndex]?.message);
    setCopiedMessageId &&
      setCopiedMessageId(messages[currentMessageIndex]?.suggestionId);
    trackMessageSuggestionCopied({
      messageSuggestionId: messages[currentMessageIndex]?.suggestionId
    });
  };

  const handleMessageChange = (dir: "left" | "right") => {
    if (dir === "left") {
      setCurrentMessageIndex(currentMessageIndex - 1);
      trackMessageSuggestionChanged({
        from: messages[currentMessageIndex]?.suggestionId,
        to: messages[currentMessageIndex - 1]?.suggestionId
      });
    } else {
      setCurrentMessageIndex(currentMessageIndex + 1);
      trackMessageSuggestionChanged({
        from: messages[currentMessageIndex]?.suggestionId,
        to: messages[currentMessageIndex + 1]?.suggestionId
      });
    }
  };

  const handleCloseModal = (confirm: boolean) => {
    setShowReportModal(false);

    //If the user cancels, we don't need to do anything
    if (!confirm) return;

    const reportReasonsValues = Array.from(reportReasons.values());

    //If the user doesn't select any reasons or write a message, we don't need to report the message
    if (reportReasonsValues.every((value) => !value) && reportMessage === "") {
      return;
    }

    reportMessageMutation({
      suggestionId: messages[currentMessageIndex]?.suggestionId,
      message: messages[currentMessageIndex]?.message,
      user_id: parseInt(userId),
      issues: Array.from(reportReasons.entries())
        .filter(([, value]) => value)
        .map(([key]) => key),
      note: reportMessage
    });

    //Reset State
    setReportMessage("");
    setReportReasons(new Map(DEFAULT_REPORT_REASONS));
  };

  const handleKeyDown = (
    event:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLInputElement>,
    onClick: () => void
  ) => {
    if (event.key === "Enter") {
      onClick();
    }
  };

  const disabled = isLoading || isError || messages.length === 0;

  return (
    <>
      <div className={styles.container}>
        <h4>{title}</h4>
        <div className={styles.wrapper}>
          {isLoading && (
            <div className={styles.loadingWrapper}>
              <PulseLoader inverted />
            </div>
          )}
          <div className={styles.message}>
            {isError && <p>{t("nextStep.errorFetchingMessages")}</p>}
            {messages && messages.length > 0 && (
              <p key={messages[currentMessageIndex]?.suggestionId}>
                {messages[currentMessageIndex]?.message ??
                  t("nextStep.errors.noMessage")}
              </p>
            )}
          </div>
          <div className={styles.actions}>
            <div className={styles.nav}>
              {!disabled && (
                <>
                  <button
                    className={styles.button}
                    disabled={currentMessageIndex === 0}
                    onClick={() => handleMessageChange("left")}
                    onKeyDown={(e) =>
                      handleKeyDown(e, () => handleMessageChange("left"))
                    }
                  >
                    <img src={ChevronLeft} alt="Left" />
                  </button>
                  {currentMessageIndex + 1} / {messages.length}
                  <button
                    className={styles.button}
                    disabled={currentMessageIndex === messages.length - 1}
                    onClick={() => handleMessageChange("right")}
                    onKeyDown={(e) =>
                      handleKeyDown(e, () => handleMessageChange("right"))
                    }
                  >
                    <img src={ChevronRight} alt="Right" />
                  </button>
                </>
              )}
            </div>
            <div className={styles.buttons}>
              {/* Hidden until features are implemented on the backend */}
              <button
                className={styles.button}
                disabled={disabled}
                onClick={() => setShowReportModal(true)}
                onKeyDown={(e) =>
                  handleKeyDown(e, () => setShowReportModal(true))
                }
              >
                <img src={Flag} alt="Flag" />
              </button>
              {/* <button
              className={styles.button}
              disabled={disabled}
              onClick={handleRefresh}
            >
              <img src={Refresh} alt="Refresh" />
            </button> */}
              <button
                className={styles.button}
                disabled={disabled}
                onClick={handleCopy}
                onKeyDown={(e) => handleKeyDown(e, handleCopy)}
              >
                <img src={Copy} alt="Copy" />
              </button>
            </div>
          </div>
        </div>
        <p className={styles.disclaimer}>
          <strong>{t("general.disclaimer")}:</strong>{" "}
          {t("messages.suggestedReplies.disclaimer")}
        </p>
      </div>
      {showReportModal && (
        <Modal
          title={t("nextStep.reportMessage.title")}
          onClose={() => handleCloseModal(false)}
          className={styles.modal}
        >
          <div className={styles.content}>
            <div>
              <p>{t("nextStep.reportMessage.message")}</p>

              <textarea
                className={styles.messageTextarea}
                disabled
                rows={5}
                defaultValue={messages[currentMessageIndex]?.message}
              />
            </div>
            <div>
              <p>{t("nextStep.reportMessage.reportQustion")}</p>
              <ul>
                {Array.from(reportReasons).map((value, key) => (
                  <li key={key}>
                    <CheckboxInput
                      checked={value[1]}
                      onChange={(checked) =>
                        setReportReasons(
                          (prev) => new Map(prev.set(value[0], checked))
                        )
                      }
                      onKeyDown={(e) => {
                        handleKeyDown(e, () => {
                          setReportReasons(
                            (prev) => new Map(prev.set(value[0], !value[1]))
                          );
                        });
                      }}
                      label={value[0]}
                      className={styles.reportCheckbox}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <p>{t("nextStep.reportMessage.other")}</p>
              <textarea
                className={styles.reportTextarea}
                placeholder={t("nextStep.reportMessage.writeFeedback")}
                onChange={(e) => setReportMessage(e.target.value)}
                value={reportMessage}
                autoFocus
              />
            </div>
          </div>
          <div className={styles.modalButtons}>
            <Button
              className={`${styles.modalButton} `}
              onClick={() => handleCloseModal(false)}
              onKeyDown={(e) => handleKeyDown(e, () => handleCloseModal(false))}
              inverted
              tabIndex={showReportModal ? 0 : -1}
            >
              {t("general.cancel")}
            </Button>
            <Button
              className={`${styles.modalButton} `}
              onClick={() => handleCloseModal(true)}
              onKeyDown={(e) => handleKeyDown(e, () => handleCloseModal(true))}
              tabIndex={showReportModal ? 0 : -1}
            >
              {t("nextStep.continue")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MessageSuggestion;
